import React from "react"
import { Box, Flex, Text, Link } from "@theme-ui/components"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  isList,
  renderRule,
} from "datocms-structured-text-utils"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui/index"
import ImageGallery from "./blocks/imageGallery"
import linkSwitch from "../utils/linkSwitch"
import NumbersGroup from "./blocks/numbersGroup"
import Embed from "./blocks/embed"
import { ArrowUpRight } from "react-feather"
import FaqList from "./blocks/faqList"

const RichContentStructuredText = ({ text, theme, headingColor }) => {
  const componentTheme = theme || "light"
  const light = componentTheme === "light" ? "light" : "dark"
  const dark = componentTheme === "light" ? "dark" : "light"
  const primary = componentTheme === "light" ? "primary" : "light"
  return (
    <Box
      sx={{
        "& > *:first-child": {
          marginTop: 0,
        },
        "& > *:last-child": {
          marginBottom: 0,
        },
        a: {
          color: headingColor || "primary",
        }
      }}
    >
      {text.value && (
        <StructuredText
          data={text}
          renderLinkToRecord={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsInternalLink":
                return linkSwitch(record, record.locale, "block")
              default:
                return null
            }
          }}
          renderInlineRecord={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsInternalLink":
                return (
                  <Flex
                    sx={{
                      alignItems: "center",
                      mt: 4,
                      a: { color: dark },
                      fontSize: 2,
                    }}
                  >
                    <Flex
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "full",
                        width: "50px",
                        height: "50px",
                        minWidth: "50px",
                        minHeight: "50px",
                        mr: 3,
                        backgroundColor: dark,
                      }}
                    >
                      <ArrowUpRight color={getColor(themeUiTheme, light)} />
                    </Flex>
                    {linkSwitch(record, record.locale, "block")}
                  </Flex>
                )
              case "DatoCmsExternalLink":
                return (
                  <Flex
                    sx={{
                      alignItems: "center",
                      mt: 4,
                      a: { color: dark },
                      fontSize: 2,
                    }}
                  >
                    <Flex
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "full",
                        width: "50px",
                        height: "50px",
                        minWidth: "50px",
                        minHeight: "50px",
                        mr: 3,
                        backgroundColor: dark,
                      }}
                    >
                      <ArrowUpRight color={getColor(themeUiTheme, light)} />
                    </Flex>
                    <Link
                      href={record.url}
                      rel="nofollow noopener"
                      target="blank"
                    >
                      {record.anchor}
                    </Link>
                  </Flex>
                )
              default:
                return null
            }
          }}
          renderBlock={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsFaqList":
                return (
                  <Box mt={5} mb={5}>
                    <FaqList items={record.faqList} />
                  </Box>
                )
              case "DatoCmsImageGallery":
                return (
                  <Box mt={5} mb={5}>
                    <ImageGallery
                      images={record.images}
                      fullWidth={record.fullWidth}
                      id={record.id}
                      grid={record.grid}
                    />
                  </Box>
                )
              case "DatoCmsNumbersGroup":
                return (
                  <Box mt={5} mb={5}>
                    <NumbersGroup numbers={record.numbers} key={record.id} />
                  </Box>
                )
              case "DatoCmsEmbed":
                return <Embed code={record.code} fullWidth={record.fullWidth} />
              default:
                return null
            }
          }}
          customRules={[
            renderRule(
              isHeading,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Text
                        as={`h${node.level}`}
                        variant={`h${node.level}`}
                        color={headingColor || "primary"}
                        dir="invalid"
                      >
                        {children}
                      </Text>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
            renderRule(
              isParagraph,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Text
                        as="p"
                        mb={3}
                        color={dark}
                        variant="article"
                        dir="invalid"
                      >
                        {children}
                      </Text>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
            renderRule(
              isList,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Box
                        as={node.style === "numbered" ? "ol" : "ul"}
                        sx={{
                          color: dark,
                          listStyle: "none",
                          columns: [1, 1, 1, 1],
                          margin: 0,
                          marginTop: 3,
                          marginBottom: 3,
                          padding: 0,
                          display: "table",
                          borderCollapse: "separate",
                          borderSpacing: "0 20px",
                          li: {
                            display: "table-row",
                            verticalAlign: "top",
                            counterIncrement: "inst",
                            pb: 4,
                            alignItems: "center",
                            "&::before": {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              content: "counter(inst)",
                              marginRight: 3,
                              marginLeft: 3,
                              width: "2rem",
                              height: "2rem",
                              position: "relative",
                              lineHeight: "body",
                              backgroundColor: dark,
                              color: light,
                              borderRadius: "full",
                            },
                            p: {
                              display: "table-cell",
                              mb: 0,
                            },
                          },
                        }}
                      >
                        {children}
                      </Box>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
          ]}
        />
      )}
    </Box>
  )
}

export default RichContentStructuredText
