import React, { useState, useEffect } from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import AnimatedBox from "../../utils/motion"
import { useInView } from "react-intersection-observer"
import { AnimatePresence } from "framer-motion"

const ImageAndText = ({
  title,
  body,
  image,
  label,
  subtitle,
  rightAligned,
  addMarginBottom,
  headingColor,
}) => {
  const [show, setShow] = useState(false)
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView === true && show === false) {
      setShow(true)
    }
  }, [inView])

  return (
    <Box sx={{ position: "relative", mb: addMarginBottom ? [0, 0, 7] : 0 }}>
      <Box
        sx={{
          position: ["relative", "relative", "absolute"],
          backgroundColor: "lightBackground",
          width: ["100%", "100%", "80%"],
          height: "100%",
          top: 0,
          left: rightAligned ? null : 0,
          right: rightAligned ? 0 : null,
          ".gatsby-image-wrapper": {
            height: "100%",
            width: "100%",
            display: "block",
          },
        }}
      >
        {image && <GatsbyImage image={image.gatsbyImageData} alt="" />}
      </Box>
      <Grid
        columns={[
          1,
          1,
          rightAligned ? "0fr 3fr 2fr" : "2fr 3fr",
          rightAligned ? "1fr 3fr 3fr" : "3fr 4fr",
        ]}
        gap={[0, 0, 32]}
        sx={{ py: [0, 0, 7], position: "relative" }}
      >
        <Box></Box>
        <Flex
          sx={{
            flexDirection: "column",
            backgroundColor: "dark",
            px: [4, 6, 6, 8],
            pr: [4, 4, "20%", "20%"],
            py: [5, 7],
            boxShadow: [
              "none",
              "none",
              rightAligned ? "-300px 0px" : "300px 0px",
            ],
          }}
          ref={ref}
        >
          <AnimatePresence>
            {show && (
              <>
                <AnimatedBox
                  initial={{ opacity: 0, y: 40, skewX: 10 }}
                  animate={{ opacity: 1, y: 0, skewX: 0 }}
                  transition={{
                    type: "spring",
                    damping: 10,
                    mass: 0.75,
                    stiffness: 100,
                    delay: 0.3,
                  }}
                >
                  {label && (
                    <Text
                      dir="invalid"
                      variant="caption"
                      sx={{ textTransform: "uppercase", mb: 3 }}
                      color="light"
                      as="p"
                    >
                      {label}
                    </Text>
                  )}
                  <Heading
                    dir="invalid"
                    variant="h2"
                    color={headingColor}
                    mb={4}
                    mt={0}
                    sx={{ fontSize: [5, 5, 5, 6] }}
                  >
                    {title}
                  </Heading>
                  {subtitle && (
                    <Text
                      dir="invalid"
                      variant="h4"
                      as="p"
                      color="light"
                      sx={{ lineHeight: 1.1, mb: [4, 4] }}
                    >
                      {subtitle}
                    </Text>
                  )}
                </AnimatedBox>
                <Box>
                  <RichContentStructuredText
                    text={body}
                    theme="dark"
                    headingColor={headingColor}
                  />
                </Box>
              </>
            )}
          </AnimatePresence>
        </Flex>
        {rightAligned && <Box></Box>}
      </Grid>
    </Box>
  )
}

export default ImageAndText
